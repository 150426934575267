export default {
  "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
  "Docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentación"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
  "Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
  "Log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
  "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instrucciones"])},
  "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesar"])},
  "Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones"])},
  "Advanced options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones avanzadas"])},
  "Process buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesar edificios"])},
  "Process addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesar direcciones"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
  "admin-boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["límites administrativos"])},
  "project-size-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tamaño del proyecto es adecuado para procesar."])},
  "project-size-ko": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El proyecto es muy grande. Se recomienda seleccionar un distrito o barrio. Si no están definidos en OSM crea relaciones de ", _interpolate(_list(0)), " con nivel 9 o 10."])},
  "Source date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de publicación"])},
  "Feature count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de características"])},
  "Pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscinas"])},
  "Building parts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partes de edificios"])},
  "Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar"])},
  "Review done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisiones completadas"])},
  "Review street names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar callejero"])},
  "review_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Revisa los nombres de las vías/lugares mostrados en el panel siguiendo estas ", _interpolate(_list(0)), ". La columna izquierda muestra el nombre de la vía/lugar en Catastro, la derecha la conversión propuesta que se usará al reprocesar. Edita las que no sean correctas o valida (", _interpolate(_list(1)), ") las que si. Déjala en blanco (", _interpolate(_list(2)), ") para excluir todas las direcciones de esa vía. Cuando termines continúa al siguiente paso."])},
  "Reprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocesar"])},
  "Check fixmes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corregir errores"])},
  "Locked by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueado por"])},
  "Edited by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editado por"])},
  "fixmes left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Falta ", _interpolate(_named("n")), " fixme."]), _normalize(["Faltan ", _interpolate(_named("n")), " fixmes."])])},
  "highways left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Falta ", _interpolate(_named("n")), " vía."]), _normalize(["Faltan ", _interpolate(_named("n")), " vías."])])},
  "reviewed_hgw": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Revisadas ", _interpolate(_list(0)), " de ", _interpolate(_list(1)), " calles."])},
  "reviewed_files": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Revisados ", _interpolate(_list(0)), " de ", _interpolate(_list(1)), " archivos."])},
  "fixme_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Descarga (", _interpolate(_named("download")), ") los archivos mostrados en el panel. Edítalos con JOSM siguiendo estas ", _interpolate(_named("link")), ". Guarda los resultados y sube los archivos corregidos (", _interpolate(_named("upload")), "). Revisa los edificios que tengan etiquetas \"fixme\" y elimínalas. No hay que subir los datos a OSM, sino aquí."])},
  "welldone1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Bien hecho! Confirma para continuar."])},
  "welldone2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Bien hecho! Reprocesa para continuar."])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "finish_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Proceso de ", _interpolate(_list(0)), " finalizado"])},
  "Publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar"])},
  "Task manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestor de tareas"])},
  "done_msg1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Crea un nuevo proyecto en el ", _interpolate(_list(0)), " usando el archivo ", _interpolate(_list(1)), "."])},
  "done_msg2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Completa los campos necesarios copiándolos (", _interpolate(_list(0)), ") de la plantilla mostrada en el panel."])},
  "done_msg3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ver el ", _interpolate(_list(0)), "."])},
  "process result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultado del proceso"])},
  "You can also": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["También puedes"])},
  "Buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificios"])},
  "Addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direcciones"])},
  "Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar"])},
  "export_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga los resultados del trabajo como copia de seguridad."])},
  "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
  "Owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietario"])},
  "delete_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar el proceso (cuidado, acción no reversible)."])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "Chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charla"])},
  "Copy to clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar al portapapeles"])},
  "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo"])},
  "Spanish (es)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español (es)"])},
  "English (en)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglés (en)"])},
  "Name of the project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del proyecto"])},
  "Short description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción corta"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "Changeset comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario del conjunto de cambios"])},
  "Detailed instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrucciones detalladas"])},
  "Per task instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrucciones adicionales para esta tarea"])},
  "Select only downloaded files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione sólo los archivos descargados"])},
  "Drop them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suéltalos"])},
  "Drop here or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrastra los archivos corregidos aquí o "])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selecciónalos"])},
  "Select reviewed file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar archivo corregido"])},
  "Sorry, no matching option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo siento, opción no encontrada"])},
  "Select the province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona la provincia..."])},
  "Select the municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el municipio..."])},
  "Select the subarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona la división..."])},
  "admin boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisiones administrativas"])},
  "select_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Selecciona una provincia y el municipio a procesar. Si existen ", _interpolate(_list(0)), " del municipio (admin_level = 9 - distritos o admin_level = 10 – barrios), también puedes seleccionar una."])},
  "Log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
  "The process is locked by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El proceso está bloqueado por"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar"])},
  "Delete filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar filtro"])},
  "Name in Cadastre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre en Catastro"])},
  "Conversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversión"])},
  "joined": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " se unió a ", _interpolate(_named("room"))])},
  "leave": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " abandonó ", _interpolate(_named("room"))])},
  "Procesando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesando"])},
  "Pendiente de revisar direcciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente de revisar direcciones"])},
  "Pendiente de revisar problemas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente de revisar problemas"])},
  "Proceso finalizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso finalizado"])},
  "No procesado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No procesado"])},
  "Terminó con error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminó con error"])},
  "Autorización denegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorización denegada"])},
  "No es un archivo gzip válido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es un archivo gzip válido"])},
  "Sólo archivos de tareas existentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo archivos de tareas existentes"])},
  "No se pudo eliminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo eliminar"])},
  "Proceso eliminado correctamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso eliminado correctamente"])},
  "Proceso no encontrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso no encontrado"])},
  "create_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " inicia el proceso de ", _interpolate(_named("room"))])},
  "restart_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " reinicia el proceso de ", _interpolate(_named("room"))])},
  "delete_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " elimina el proceso de ", _interpolate(_named("room"))])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hecho"])},
  "view_in_osm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver en OpenStreetMap"])},
  "Validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar"])},
  "Undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deshacer"])},
  "parcel_parts_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de partes de edificio para comenzar a agregar parcelas para tareas."])},
  "parcel_dist_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia en metros para agregar parcelas para tareas."])},
  "between_val": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El valor debe estar entre ", _interpolate(_list(0)), " y ", _interpolate(_list(1)), "."])},
  "add_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear proyecto"])},
  "Share project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir proyecto"])},
  "Explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorar"])},
  "Source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen"])},
  "Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyecto"])},
  "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
  "Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tareas"])},
  "parts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partes"])},
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponible"])},
  "RUNNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ejecutándose"])},
  "DONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terminado"])},
  "REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["callejero"])},
  "FIXME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correcciones"])},
  "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error"])},
  "hero title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importación del Catastro Español a OpenStreetMap"])},
  "hero subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Herramienta en línea para convertir edificios y direcciones de los ", _interpolate(_list(0)), " del Catastro a archivos ", _interpolate(_list(1)), "."])},
  "Servicios ATOM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios ATOM"])},
  "hero content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las importaciones y ediciones automatizadas deben ser llevadas a cabo por personas con experiencia y suficiente conocimiento de la forma en que la comunidad OpenStreetMap crea mapas y con una cuidada planificación y consulta con la comunidad local correspondiente."])},
  "hero content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Consulta la ", _interpolate(_list(0)), ". Las importaciones o ediciones automatizadas que no sigan estas directrices ¡se pueden revertir!"])},
  "import guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guía de importación"])},
  "docview title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de proyectos"])},
  "docview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta herramienta en linea sirve para convertir datos de edificios y direcciones de Catastro al formato de OSM. La conversión se realiza por zonas, generalmente un municipio y el resultado es un proyecto de importación disponible para que distintos usuarios puedan llevar a cabo la importación de forma colaborativa."])},
  "docview content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Es interesante que antes de crear tu primer proyecto conozcas cómo es el proceso de participación en los que ya están publicados. Anímate a colaborar en ellos siguiendo la ", _interpolate(_list(0)), "."])},
  "docview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para crear un proyecto de una zona no publicada es necesario dar estos pasos:"])},
  "docview login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
  "docview select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar"])},
  "docview process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesar"])},
  "docview review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar el callejero"])},
  "docview fixme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corregir errores"])},
  "docview publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicación"])},
  "docview need help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Necesitas ayuda?"])},
  "docview need help explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No dudes en contactar a través de cualquiera de estos medios:"])},
  "docview mail list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de correo Talk-es"])},
  "docview telegram matrix": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Canal de ", _interpolate(_list(0)), " / ", _interpolate(_list(1))])},
  "docview forum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Foro ", _interpolate(_list(0))])},
  "docview need help end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allí encontrarás otros usuarios dispuestos a resolver tus dudas o colaborar en lo necesario."])},
  "docselectview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sigue estas instrucciones para seleccionar la zona a procesar:"])},
  "docselectview province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona una provincia."])},
  "docselectview municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona un municipio."])},
  "docselectview check size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprueba el tamaño que tendrá el proyecto."])},
  "docselectview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Después de seleccionar un municipio, en el panel de información tendrás datos que te permiten hacerte una idea del tamaño que tendrá el proyecto. Mientras más edificios o direcciones mayor será el trabajo. Interesa que un proyecto no sea muy grande, especialmente si es la primera vez que creas uno. Mientras más grande sea, más tiempo será necesario para la revisión prévia a su publicación y para completar la importación una vez publicado."])},
  "docselectview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como regla general, podemos decir que un proyecto es demasiado grande si el número de partes de edificio es superior a 10000. El programa te alertará en caso de superar esta cifra. Si el proyecto es grande y no quieres empezar por otro municipio menor, intenta seleccionar una división administrativa."])},
  "docselectview admin level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcionalmente, selecciona una división administrativa."])},
  "docselectview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Los municipio se suelen dividir en entidades administrativas menores. En OSM, los municipios tienen la etiqueta ", _interpolate(_list(0)), ". Por debajo, con ", _interpolate(_list(1)), ", están los distritos (el nombre puede cambiar según las peculiaridades de cada territorio). Dentro de de cada distrito existirán barrios (o el equivalente en tu zona), con ", _interpolate(_list(2)), "."])},
  "docselectview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si estos límites administrativos están definidos en OSM, aparecerán en el selector de división administrativa debajo del selector de municipio. Selecciona un barrio que tenga el tamaño adecuado para procesar."])},
  "docselectview content6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Si los ", _interpolate(_list(0)), " no están definidos en OSM, es recomendable crear los que necesites. Si vas a utilizar datos de terceros para dibujar estos límites y no están publicados con una licencia compatible será necesario solicitar una licencia expresa de uso. Consulta en tu Ayuntamiento y pide ayuda para dar los pasos necesarios."])},
  "docloginview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sigue estas instrucciones para acceder a CatAtom2Osm online:"])},
  "docloginview content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Haz clic en el botón ", _interpolate(_list(0)), " de la barra de navegación."])},
  "docloginview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión en OpenStreetMap usando tu cuenta o uno de los métodos alternativos."])},
  "Grant Access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otorgar acceso"])},
  "docloginview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Autoriza el acceso en CatAtom2Osm haciendo clic en ", _interpolate(_list(0)), "."])},
  "docloginview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cuenta dedicada de importación es necesaria para contribuir en proyectos publicados. Para crearlos puedes usar tu cuenta normal si lo deseas."])},
  "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota"])},
  "docprocessview content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de proceso."])},
  "docprocessview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez seleccionada la zona a procesar debes decidir si quieres importar al mismo tiempo edificios y direcciones postales o procesar independientemente edificios o direcciones. La opción más completa es la primera, aunque algunos usuarios deciden procesar sólo direcciones si el mapa ya contiene muchos edificios, o procesar sólamente los edificios para reducir la carga de trabajo."])},
  "docprocessview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El selector de idioma se refiere al idioma empleado al convertir las abreviaturas de tipo de vía empleadas en Catastro (por ejemplo, CL = Calle/Carrer/Rúa). Se ajusta en función de la provincia, por lo que en principio no es necesario cambiarlo."])},
  "docprocessview content4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones avanzadas."])},
  "docprocessview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dentro del apartado opciones avanzadas se encuentran parámetros para modificar la forma en que el programa agrupa las parcelas con edificios o direcciones en archivos de tareas para importar. Se recomienda utilizar los valores por defecto."])},
  "docprocessview content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El programa intentará que los ficheros de tareas tengan como máximo este número de partes de edificio."])},
  "docprocessview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se fusionarán las parcelas más cercanas que esta distancia si no se supera el número de partes."])},
  "docprocessview content8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia el proceso."])},
  "docprocessview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando estés listo haz clic en el botón"])},
  "docprocessview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información."])},
  "docprocessview content11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durante la ejecución del proceso de conversión se actualiza el panel de registro con información de la ejecución del programa."])},
  "docprocessview content12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al terminar se muestra un informe con datos sobre el resultado de la ejecución."])},
  "docreviewview content1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Antes de poder publicar un proyecto de importación existen dos revisiones que hay que realizar manualmente. La primera, es la del callejero. Si no has seleccionado ", _interpolate(_list(0)), ", puedes pasar a ", _interpolate(_list(1))])},
  "docreviewview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversión de viales"])},
  "docreviewview content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se deben importar los nombres de calles tal como vienen de Catastro. Incumplen las reglas de OSM (están en mayúsculas, sin tildes y con otros problemas). CatAtom2Osm ayuda a corregirlos intentando emparejar automáticamente el nombre de cada vía en Catastro con el nombre en OSM más parecido y cercano. Si no encuentra una coincidencia realiza una transformación preliminar como sugerencia de cuál debería ser el nombre correcto. En cualquier caso, hace falta revisar el proceso manualmente."])},
  "docreviewview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La revisión se hace mediante esta tabla. En la columna de la izquierda se muestra el ", _interpolate(_list(0)), " del vial, en la de la derecha la ", _interpolate(_list(1)), " propuesta."])},
  "docreviewview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al hacer clic en una fila aparece un mapa que muestra los portales de Catastro existentes en esa calle y los viales existentes en OSM. Haz clic en los portales para acceder a las foto de fachada de Catastro. Al colocar el ratón sobre un vial se muestra el nombre en OSM."])},
  "docreviewview content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes usar el selector de capas para alternar entre el mapa de OSM y el de IGN-Base que muestra el callejero de Cartociudad, o para desactivar las capas de Portales o Viales si no te dejan ver bien el mapa."])},
  "docreviewview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validación"])},
  "docreviewview content8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiendo los portales de Catastro, busca con que vial de OSM se corresponden."])},
  "docreviewview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la calle tiene nombre en OSM, está correctamente escrito, es igual a la conversión propuesta y se corresponde con el nombre que figura en Catastro, puedes validar la conversión usando el botón"])},
  "docreviewview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las filas de las calles revisadas se muestran con un color diferenciado para cada usuario que ha hecho la revisión. También puedes consultarlo al poner el ratón encima del recuadro de edición del nombre de la calle. Distintos usuarios pueden colaborar para completar la revisión."])},
  "docreviewview content11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrección"])},
  "docreviewview content12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la calle no tiene nombre en OSM, o si lo tiene pero no es igual a la conversión propuesta, busca el nombre correcto usando revisión sobre el terreno o las distintas fuentes disponibles (Cartociudad, las fotos de fachada, Mapillary, KartaView)."])},
  "docreviewview content13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando tengas el nombre correcto edita la conversión en la tabla y/o el nombre de la vía en OSM según sea necesario. Para acceder a editar el mapa, en el encabezado del mapa hay un enlace a la web de OSM."])},
  "docreviewview content14": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Las ediciones en el mapa resultado de esta fase deben hacerse con la cuenta de usuario habitual de OSM, no con cuentas dedicadas de importación. Conviene etiquetarlas con ", _interpolate(_list(0)), " y el nombre del municipio en el comentario del conjunto de cambios."])},
  "docreviewview content15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si una calle se muestra con distintos colores a lo largo de su trazado, es por que está formada por distintas vías en OSM y el nombre no coincide. Hay que editar el mapa, ver cual es el correcto y ponerlo en todos los tramos. En este ejemplo, al nombre en un tramo le falta una tilde."])},
  "docreviewview content16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando el programa no encuentra una vía en OSM con la que emparejar los nombres, aplicará correcciones al nombre en Catastro para sugerirlo como conversión. Vigila la conversión de las abreviaturas del tipo de vía. Existen algunas que tienen varios valores posibles que se muestran separados por una barra. Son los casos de AL (Aldea/Alameda), CJ (Calleja/Callejón) o CR (Carretera/Carrera), por ejemplo. En caso de usarlos, revisa que sólo quede el tipo de vía correcto o ningúno, según el caso."])},
  "docreviewview content17": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Algunas direcciones de Catastro pueden referirse a lugares en vez de a vías, por ejemplo a barrios, urbanizaciones, caseríos. Comprueba si el nombre está mapeado en OSM o se puede añadir. Ten en cuenta que el programa asigna las direcciones a la etiqueta ", _interpolate(_list(0)), " en lugar de ", _interpolate(_list(1)), " cuando el nombre comienza por referencias de lugar. Para una lista completa consulta el anexo."])},
  "docreviewview content18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "docreviewview content19": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Algunos nombres de vía de Catastro pueden no ser adecuados para importar en OSM. Por ejemplo podemos encontrar referencias administrativa tales como 'MANZANA A SECTOR 4' que no se corresponde con nombres de vía reales. Si decides que no se importen las direcciones para un nombre de vía, deja la conversión en blanco mediante el botón ", _interpolate(_list(0))])},
  "docreviewview content20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deshacer"])},
  "docreviewview content21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para restaurar la conversión a su valor original usa el botón"])},
  "docreviewview content22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocesar"])},
  "docreviewview content23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando esté completa la revisión del callejero, el usuario que inició el proceso puede continuar al siguente paso mediante el botón"])},
  "docreviewview content24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anexo: Siglas de tipo de vía"])},
  "docreviewview content25": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["*) Se aplica a ", _interpolate(_list(0)), " en lugar de ", _interpolate(_list(1)), "."])},
  "docreviewview content26": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["**) Se aplica a ", _interpolate(_list(0)), " y se elimina el prefijo \"Lugar\" en la conversión"])},
  "docreviewview content27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["***) Se eliminan las direcciones de estos tipos."])},
  "docfixmeview content1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En esta fase, ya está completada la conversión de datos. Se han creado cada unidad de importación (o tareas). Sin embargo, antes de poder publicar el proyecto de importación hay que revisar los problemas que el programa ha detectado y marcado en los edificios mediante etiquetas ", _interpolate(_list(0)), "."])},
  "docfixmeview content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La revisión se hace mediante esta lista. Cada fila contiene el enlace al archivo de una tarea con problemas y el número de fixmes que contiene."])},
  "docfixmeview content3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Descarga ", _interpolate(_list(0)), " el archivo a tu ordenador y ábrelo con JOSM. Haz una búsqueda del texto 'fixme' para localizar los elementos que tengan esa etiqueta. Lee el mensage del problema detectado y busca su descripción en esta página. Elimina la etiqueta fixme y si es necesario corrige el problema indicado. Para resolver el problema consulta la imagen aérea de fondo, las fotos de fachada y otras fuentes aprobadas de imágenes como Mapillary o KartaView."])},
  "docfixmeview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["No subas los archivos de tareas a OSM. Cuando estén corregidos los problemas y no queden etiquetas fixme guardalo en tu ordenador y súbelo a CatAtom2OSM. Puedes arrastrar los archivos al panel o usar los botones ", _interpolate(_list(0)), "."])},
  "docfixmeview content5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las filas de los archivos revisados se muestran con un color diferenciado para cada usuario que ha hecho la revisión. También puedes consultarlo al poner el ratón encima del enlace de descarga. Distintos usuarios pueden colaborar para completar la revisión."])},
  "docfixmeview content6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los 'fixmes' están pensados para corregirlos antes publicar el proyecto. Se debe reducir su número a 0 para poder hacerlo. Esta es la lista de posibles problemas a solucionar:"])},
  "docfixmeview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Área demasiado grande"])},
  "docfixmeview content8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio con área demasiado grande. Si no se corresponde a un edificio real, elimínalo."])},
  "docfixmeview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Área demasiado pequeña"])},
  "docfixmeview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio con área demasiado pequeña. Si no se corresponde a un edificio real, elimínalo."])},
  "docfixmeview content11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta parte es mayor que su edificio"])},
  "docfixmeview content12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Significa que una parte de un edificio está fuera del contorno del mismo. Resuélvelo ampliando el contorno del edificio o recortando la parte según sea oportuno."])},
  "docfixmeview content13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validación GEOS"])},
  "docfixmeview content14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La geometría no ha superado las pruebas de validación de la librería GEOS. En este ejemplo, una parte interior de un multipolígono está tocando el borde exterior del mismo. Se puede resolver eliminando la relación multipolígono y colocando las etiquetas de altura en cada parte por separado."])},
  "docfixmeview content15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El edificio de OSM con id %s no es válido"])},
  "docfixmeview content16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha encontrado un edificio con geometría no válida en OSM. En este caso el problema no está en los datos de Catastro sino en los de OSM. Comprueba que el edificio de Catastro se superpone al de OSM y que será reemplazado (combinación) cuando se haga la importación. En ese caso no es necesaria ninguna acción aparte de eliminar la etiqueta 'fixme'. Si el edificio no va a ser reemplazado con los datos de Catastro al importar, hay que corregirlo en OSM."])},
  "docfixmeview content17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deshacer"])},
  "docfixmeview content19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocesar"])},
  "docfixmeview content20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando esté completa la revisión de todos los archivos, el usuario que inició el proceso puede continuar al siguente paso mediante el botón"])},
  "docfixmeview content21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anexo: nomenclatura de subparcelas"])},
  "docfixmeview content18": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Si te equivocas, siempre puedes restaurar el archivo de tarea original usando el botón ", _interpolate(_list(0))])},
  "docfixmeview content22": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Según ", _interpolate(_list(0))])},
  "Task Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestor de Tareas"])},
  "docpublishview content1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Finalmente, el proyecto está listo para su publicación en el ", _interpolate(_list(0)), "."])},
  "docpublishview content2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Para ello necesitas que te den permiso para crear proyectos. Manda un mensaje solicitándolo al Canal de ", _interpolate(_list(0)), " / ", _interpolate(_list(1)), " indicando tu nombre de usuario."])},
  "docpublishview content3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Cuando tengas el permiso asignado, usa el botón ", _interpolate(_list(0))])},
  "docpublishview content4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Descarga el archivo ", _interpolate(_list(0)), " ", _interpolate(_list(1)), " a tu ordenador."])},
  "Select file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar archivo"])},
  "docpublishview content5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En la ventana 'CREAR NUEVO PROYECTO, DEFINIR ÁREA' del Gestor de Tareas haz clic en ", _interpolate(_list(0)), " y selecciona el archivo zoning.zip descargado."])},
  "important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importante"])},
  "docpublishview content6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), ": Activa la opción para ", _interpolate(_list(1)), "."])},
  "docpublishview content7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir tareas mediante los polígonos cargados"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
  "docpublishview content8": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Haz clic en ", _interpolate(_list(0)), " para continuar."])},
  "docpublishview content9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En la ventana 'REVISAR', comprueba que el número de tareas mostrado coincide con el que se muestra en CatAtom2OSM."])},
  "docpublishview content10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona la organización OSM-es."])},
  "docpublishview content11": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Introduce el nombre del proyecto en inglés. A partir de ahora, completa los campos necesarios copiándolos de la plantilla mostrada en el panel mediante los botones ", _interpolate(_list(0))])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
  "docpublishview content12": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Haz clic en ", _interpolate(_list(0)), " para crear el proyecto."])},
  "docpublishview content13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En la página 'EDITAR PROYECTO', 'DESCRIPCIÓN', selecciona el estado 'Publicado'. Completa en inglés y español los campos 'Nombre del proyecto', 'Descripción corta' y 'Descripción'."])},
  "docpublishview content14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En 'INSTRUCCIONES', completa en inglés y español los campos 'Comentario al conjunto de cambios', 'Instrucciones detalladas' y 'Instrucciones adicionales para esta tarea'."])},
  "docpublishview content15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En 'METADATOS', 'Tipos de mapeo', selecciona 'Edificios'."])},
  "docpublishview content16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz clic en el botón 'Guardar' para finalizar."])}
}